import { useState } from "react";
import { createContainer } from "react-tracked";

const useRestakeTab = () => useState<"deposit" | "unstake">("deposit");

const { Provider: RestakeTabProvider, useTracked: useRestakeTabTracked } =
  createContainer(useRestakeTab);

export default {
  RestakeTabProvider,
  useRestakeTabTracked,
};
