import "./App.css";
import Router from "./router";
import "@solana/wallet-adapter-react-ui/styles.css";
import "./translations";

function App() {
  return <Router />;
}

export default App;
