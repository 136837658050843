import { useState } from "react";
import { createContainer } from "react-tracked";

const useAssetCollapse = () => useState<boolean>(true);

const { Provider: AssetCollapseProvider, useTracked: useAssetCollapseTracked } =
  createContainer(useAssetCollapse);

export default {
  AssetCollapseProvider,
  useAssetCollapseTracked,
};
