import GalleryCards, { CoinList } from "../../components/gallery-card";
import MeshedRestakingForm from "../../components/meshed-restaking-form";
import { HeroContainer, HeroTitle, HeroValue } from "../../components/hero";
import { useMemo } from "react";
import { BigNumber } from "allstake-sdk";
import toLocaleString from "../../utils/toLocaleString";
import { useTranslation } from "react-i18next";
import GalleryCardsContext from "../../context/GalleryCardsContext";
import { twc } from "react-twc";
import { ArrowDownImg } from "../../assets";
import clsx from "clsx";
import AssetCollapseContext from "../../context/AssetCollapseContext";

const { useGalleryCardsTracked } = GalleryCardsContext;
const { useAssetCollapseTracked } = AssetCollapseContext;
function Restake() {
  const [galleryCards] = useGalleryCardsTracked();
  const sumValue = useMemo(() => {
    if (galleryCards[0] && galleryCards[0].valueInUsdRaw === undefined) {
      return "-";
    }
    return galleryCards.reduce(
      (prev, cur) => (cur.valueInUsdRaw ?? BigNumber(0)).plus(prev),
      BigNumber(0),
    );
  }, [galleryCards]);
  const { t } = useTranslation();

  const [assetCollapse] = useAssetCollapseTracked();
  return (
    <>
      <HeroContainer>
        <HeroTitle>{t("restake.tvl")}</HeroTitle>
        <HeroValue>
          ${sumValue === "-" ? "-" : toLocaleString(sumValue.toFixed(), 0)}
        </HeroValue>
      </HeroContainer>
      {assetCollapse && <CoinList />}
      <GalleryCards
        className={clsx(!assetCollapse ? "scale-100" : "scale-0")}
      />
      <ViewDetails />
      <MeshedRestakingForm />
    </>
  );
}

const ViewDetails = () => {
  const { t } = useTranslation();
  const [assetCollapse, setAssetCollapse] = useAssetCollapseTracked();
  return (
    <ViewDetailsContainer
      onClick={() => setAssetCollapse(!assetCollapse)}
      className={clsx(!assetCollapse ? "mt-[300px]" : "mt-[90px]")}
    >
      {assetCollapse && <p>{t("restake.viewDetails")}</p>}
      {!assetCollapse && <p>{t("restake.showLess")}</p>}
      <img
        className={clsx("w-5 h-5", !assetCollapse && "rotate-180")}
        src={ArrowDownImg}
      />
    </ViewDetailsContainer>
  );
};

const ViewDetailsContainer = twc.div`
  flex gap-1 justify-center items-center
  text-[#FFFFFF99] cursor-pointer select-none
  duration-300
`;

export default Restake;
