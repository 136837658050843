import { useEffect, useState } from "react";
import useAllstakeSdk from "./useAllstakeSdk";
import { useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import SymbolInputContext from "../context/SymbolInputContext";
import { Amount } from "allstake-sdk";
import BN from "bn.js";
import { useWalletSelector } from "../context/WalletSelectorContext";
import Big from "big.js";
import { formatShownDigit } from "../utils/number";
import { SECOND } from "../utils/time";
import { useAccount } from "wagmi";
import { sharesToBalance } from "allstake-sdk/dist/ethereum";
import { getErc20Balance } from "../utils/erc20";
import { Address } from "viem";

const { useSymbolInputTracked } = SymbolInputContext;

function useWithdrawAmount(
  refresh?: boolean,
  setRefresh?: React.Dispatch<React.SetStateAction<boolean>>,
) {
  const allstakeSdk = useAllstakeSdk();
  const wallet = useWallet();
  const [symbolInput] = useSymbolInputTracked();
  const [data, setData] = useState<
    Record<
      string,
      {
        withdrawAmount: {
          raw: BN;
          formatted: string;
        };
        withdrawAvailableTime: number;
        disabledWithdrawDueTime: boolean;
      }
    >
  >({});
  const { accountId } = useWalletSelector();
  const { address: ethAddress } = useAccount();
  const _refreshWithdrawAmount = async () => {
    if (
      !allstakeSdk ||
      !allstakeSdk.solana ||
      !allstakeSdk.solana.strategyManagerProgram ||
      !wallet?.publicKey
    ) {
      return;
    }
    return allstakeSdk.solana.strategyManagerProgram
      .getQueuedWithdrawAmount(
        new PublicKey(symbolInput.address),
        wallet.publicKey,
      )
      .then((amount: BN) => {
        return {
          raw: amount,
          formatted: formatShownDigit(
            Amount.format(amount.toString(), symbolInput.decimals),
          ),
        };
      })
      .catch((err) => {
        console.error(err);
        return {
          raw: new BN(0),
          formatted: "0.0000",
        };
      });
  };

  const _refreshSolanaAvailableTime = async () => {
    if (
      !allstakeSdk ||
      !allstakeSdk.solana ||
      !allstakeSdk.solana.strategyManagerProgram ||
      !wallet?.publicKey
    ) {
      return;
    }
    return allstakeSdk.solana.strategyManagerProgram
      .getWithdrawAvailableTime(
        new PublicKey(symbolInput.address),
        wallet.publicKey,
      )
      .then((timestamp) => {
        return timestamp;
      });
  };

  const _refreshNearWithdrawData = async () => {
    if (
      !allstakeSdk ||
      !allstakeSdk.near ||
      !allstakeSdk.near.strategyManagerContract ||
      !accountId
    ) {
      return;
    }
    // get withdraw amount
    const strategies =
      await allstakeSdk.near.strategyManagerContract.getStrategies({});
    let amount = Big(0);
    const targetStrategies: string[] = [];
    const targetStrategiesStartAt: number[] = [];
    const minimalWithdrawalDelay =
      await allstakeSdk.near.strategyManagerContract.getMinimumWithdrawalDelay(
        {},
      );
    const withdrawal =
      await allstakeSdk.near.strategyManagerContract.getQueuedWithdrawals({
        staker: accountId,
      });
    withdrawal.forEach((withdraw) => {
      const withdrawStrategies = withdraw.strategies;
      const withdrawShares = withdraw.shares;
      withdrawStrategies.forEach((withdrawStrategyId, idx) => {
        const strategy = strategies.find(
          (strategy) => strategy.id === withdrawStrategyId,
        );
        if (strategy && strategy.underlying_token === symbolInput.address) {
          amount = amount.plus(withdrawShares[idx]);
          targetStrategies.push(strategy.id);
          targetStrategiesStartAt.push(withdraw.start_at);
        }
      });
    });

    // getWithdrawAvailableTime = Max(getMinimumWithdrawalDelay, getStrategiesWithdrawalDelay) + Max(withdrawals.start_at)
    const deplyTimes =
      await allstakeSdk.near.strategyManagerContract.getStrategiesWithdrawalDelay(
        {
          strategies: targetStrategies,
        },
      );
    const timestamp =
      Math.max(...deplyTimes, minimalWithdrawalDelay) +
      Math.max(...targetStrategiesStartAt);

    return {
      withdrawAmount: {
        raw: new BN(amount.toFixed()),
        formatted: formatShownDigit(
          Amount.format(amount.toFixed(), symbolInput.decimals),
        ),
      },
      withdrawAvailableTime: timestamp,
      disabledWithdrawDueTime: timestamp > Date.now(),
    };
  };

  const _refreshEthWithdrawData = async () => {
    if (
      !allstakeSdk ||
      !allstakeSdk.ethereum ||
      !allstakeSdk.ethereum.strategyManagerContract ||
      !allstakeSdk.ethereum.uiDataProviderContract ||
      !ethAddress
    ) {
      return;
    }
    const [requests, strategyData, userStrategyData] = await Promise.all([
      allstakeSdk.ethereum.uiDataProviderContract.userQueueWithdrawalRequests(
        symbolInput.address,
        ethAddress,
      ),
      allstakeSdk.ethereum.uiDataProviderContract.strategyDataByToken(
        symbolInput.address,
      ),
      allstakeSdk.ethereum.uiDataProviderContract.userStrategyData(
        symbolInput.address,
        ethAddress,
      ),
    ]);
    const withdrawAvailableTimeRaw =
      Math.max(...requests.map((request) => Number(request.timestamp))) +
      Math.max(
        Number(strategyData.strategyWithdrawDelay),
        Number(strategyData.minWithdrawDelay),
      );

    const queuedWithdrawShares = requests.reduce((prev, cur) => {
      return prev + cur.shares;
    }, BigInt(0));
    const withdrawAvailableTime = withdrawAvailableTimeRaw * SECOND;
    const disabledWithdrawDueTime = withdrawAvailableTime > Date.now();
    const strategyTotalBalance = await getErc20Balance(
      symbolInput.address as Address,
      userStrategyData.strategy as Address,
    );
    const withdrawAmount = sharesToBalance(queuedWithdrawShares, {
      strategyTotalBalance,
      strategyTotalShares: strategyData.strategyTotalShares,
    });
    return {
      disabledWithdrawDueTime,
      withdrawAmount: {
        raw: new BN(withdrawAmount.toString()),
        formatted: formatShownDigit(
          Amount.format(withdrawAmount.toString(), symbolInput.decimals),
        ),
      },
      withdrawAvailableTime,
    };
  };
  useEffect(() => {
    if (symbolInput.chain === "solana") {
      _refreshWithdrawAmount().then((withdrawAmount) => {
        if (withdrawAmount) {
          setData((prev) => ({
            ...prev,
            [symbolInput.address]: {
              ...prev[symbolInput.address],
              withdrawAmount,
            },
          }));
        }
      });
      _refreshSolanaAvailableTime().then((timestamp) => {
        if (timestamp) {
          setData((prev) => ({
            ...prev,
            [symbolInput.address]: {
              ...prev[symbolInput.address],
              withdrawAvailableTime: timestamp,
              disabledWithdrawDueTime: timestamp > Date.now(),
            },
          }));
        }
      });
    } else if (symbolInput.chain === "near") {
      _refreshNearWithdrawData().then((data) => {
        if (data) {
          setData((prev) => ({
            ...prev,
            [symbolInput.address]: { ...data },
          }));
        }
      });
    } else if (symbolInput.chain === "eth") {
      _refreshEthWithdrawData().then((data) => {
        if (data) {
          setData((prev) => ({
            ...prev,
            [symbolInput.address]: { ...data },
          }));
        }
      });
    }
  }, [symbolInput, allstakeSdk, wallet.publicKey, ethAddress, accountId]);

  useEffect(() => {
    if (refresh && setRefresh) {
      if (symbolInput.chain === "solana") {
        Promise.all([
          _refreshWithdrawAmount(),
          _refreshSolanaAvailableTime(),
        ]).then(([newWithdrawAmount, newTimestamp]) => {
          if (
            newWithdrawAmount &&
            newTimestamp &&
            data[symbolInput.address]?.withdrawAmount !== newWithdrawAmount &&
            data[symbolInput.address]?.withdrawAvailableTime !== newTimestamp
          ) {
            setRefresh(false);
            setData((prev) => ({
              ...prev,
              [symbolInput.address]: {
                withdrawAmount: newWithdrawAmount,
                withdrawAvailableTime: newTimestamp,
                disabledWithdrawDueTime: newTimestamp > Date.now(),
              },
            }));
          }
        });
        const interval = setInterval(() => {
          Promise.all([
            _refreshWithdrawAmount(),
            _refreshSolanaAvailableTime(),
          ]).then(([newWithdrawAmount, newTimestamp]) => {
            if (!refresh) {
              clearInterval(interval);
            }
            if (
              newWithdrawAmount &&
              newTimestamp &&
              data[symbolInput.address]?.withdrawAmount !== newWithdrawAmount &&
              data[symbolInput.address]?.withdrawAvailableTime !== newTimestamp
            ) {
              setRefresh(false);
              clearInterval(interval);
              setData((prev) => ({
                ...prev,
                [symbolInput.address]: {
                  withdrawAmount: newWithdrawAmount,
                  withdrawAvailableTime: newTimestamp,
                  disabledWithdrawDueTime: newTimestamp > Date.now(),
                },
              }));
            }
          });
        }, 1 * SECOND);
      } else if (symbolInput.chain === "near") {
        _refreshNearWithdrawData().then((_data) => {
          if (
            _data &&
            (_data.withdrawAmount !==
              data[symbolInput.address]?.withdrawAmount ||
              _data.disabledWithdrawDueTime !==
                data[symbolInput.address]?.disabledWithdrawDueTime ||
              _data.withdrawAvailableTime !==
                data[symbolInput.address]?.withdrawAvailableTime)
          ) {
            setRefresh(false);
            setData((prev) => ({
              ...prev,
              [symbolInput.address]: { ..._data },
            }));
          }
        });
        const interval = setInterval(() => {
          _refreshNearWithdrawData().then((_data) => {
            if (!refresh) {
              clearInterval(interval);
            }
            if (
              _data &&
              (_data.withdrawAmount !==
                data[symbolInput.address]?.withdrawAmount ||
                _data.disabledWithdrawDueTime !==
                  data[symbolInput.address]?.disabledWithdrawDueTime ||
                _data.withdrawAvailableTime !==
                  data[symbolInput.address]?.withdrawAvailableTime)
            ) {
              setRefresh(false);
              clearInterval(interval);
              setData((prev) => ({
                ...prev,
                [symbolInput.address]: { ..._data },
              }));
            }
          });
        }, 1 * SECOND);
      } else if (symbolInput.chain === "eth") {
        _refreshEthWithdrawData().then((_data) => {
          if (!refresh) {
            clearInterval(interval);
          }
          if (
            _data &&
            (_data.withdrawAmount !==
              data[symbolInput.address]?.withdrawAmount ||
              _data.disabledWithdrawDueTime !==
                data[symbolInput.address]?.disabledWithdrawDueTime ||
              _data.withdrawAvailableTime !==
                data[symbolInput.address]?.withdrawAvailableTime)
          ) {
            setRefresh(false);
            clearInterval(interval);
            setData((prev) => ({
              ...prev,
              [symbolInput.address]: { ..._data },
            }));
          }
        });
        const interval = setInterval(() => {
          _refreshEthWithdrawData().then((_data) => {
            if (!refresh) {
              clearInterval(interval);
            }
            if (
              _data &&
              (_data.withdrawAmount !==
                data[symbolInput.address]?.withdrawAmount ||
                _data.disabledWithdrawDueTime !==
                  data[symbolInput.address]?.disabledWithdrawDueTime ||
                _data.withdrawAvailableTime !==
                  data[symbolInput.address]?.withdrawAvailableTime)
            ) {
              setRefresh(false);
              clearInterval(interval);
              setData((prev) => ({
                ...prev,
                [symbolInput.address]: { ..._data },
              }));
            }
          });
        }, 1 * SECOND);
      }
    }
  }, [refresh]);
  return {
    dataCompleted: !!data[symbolInput.address],
    ...(data[symbolInput.address] ?? {
      withdrawAmount: {
        raw: new BN(0),
        formatted: "-",
      },
      withdrawAvailableTime: undefined,
      disabledWithdrawDueTime: true,
    }),
  };
}

export default useWithdrawAmount;
