import { useState } from "react";
import { createContainer } from "react-tracked";

const useTokenInput = () => useState<string>("");

const { Provider: TokenInputProvider, useTracked: useTokenInputTracked } =
  createContainer(useTokenInput);

export default {
  useTokenInputTracked,
  TokenInputProvider,
};
