import { twc } from "react-twc";
import BoostTag from "../boost-tag";

function GalleryCard({
  icon,
  title,
  value,
  valueInUsd,
  boostTag,
}: GalleryCardProps) {
  return (
    <GalleryCardStyle>
      <img
        className="mb-4 w-[56px] h-[56px]"
        src={icon}
        width={56}
        height={56}
      />
      <GallerySection>
        <GalleryTitle>{title}</GalleryTitle>
        <GalleryValue>{value}</GalleryValue>
        <GalleryValueInUsd>{valueInUsd}</GalleryValueInUsd>
      </GallerySection>
      <BoostTagContainer>
        {boostTag.map((tag) => (
          <BoostTag key={tag}>{tag}</BoostTag>
        ))}
      </BoostTagContainer>
    </GalleryCardStyle>
  );
}

const BoostTagContainer = twc.div`
  absolute right-2 top-2 flex flex-col gap-[5px]
`;

const GalleryCardStyle = twc.div`
  bg-[#0C111D] bg-opacity-80 p-6 
  min-w-[250px] rounded-xl
  GalleryCard relative
`;

const GallerySection = twc.div`
  flex flex-col gap-[6px]
`;

const GalleryTitle = twc.div`
  text-white text-opacity-60
`;

const GalleryValue = twc.div`
  text-[22px] font-medium
`;

const GalleryValueInUsd = twc.div`
  text-base
`;

export interface GalleryCardProps {
  symbol: string;
  icon: string;
  title: string;
  value: string;
  valueInUsd: string;
  boostTag: Array<string>;
}

export default GalleryCard;
