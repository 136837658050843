import { readContract } from "@wagmi/core";
import { wagmiConfig } from "../context/Web3ModalProvider";
import { simulateContract, writeContract } from "@wagmi/core";
import { Address, erc20Abi, maxUint256, PublicClient } from "viem";
import { waitForTransactionReceipt } from "viem/actions";
import { SymbolInput } from "../config/type";
import { StrategyData } from "allstake-sdk/dist/ethereum";
import Big from "big.js";
import { Amount } from "allstake-sdk";
import { isDigit } from "./regex";
import { MAX_TIMEOUT, SECOND, sleep } from "./time";

export async function getAllowance(
  tokenAddress: Address,
  spender: Address,
  owner: Address,
) {
  return (await readContract(wagmiConfig, {
    address: tokenAddress,
    abi: erc20Abi,
    functionName: "allowance",
    args: [owner, spender],
  })) as bigint;
}
export async function getErc20Balance(
  tokenAddress: Address,
  userAddress: Address,
): Promise<bigint> {
  return (await readContract(wagmiConfig, {
    address: tokenAddress,
    abi: erc20Abi,
    functionName: "balanceOf",
    args: [userAddress],
  })) as bigint;
}

export async function approve(
  tokenAddress: Address,
  spender: Address,
  client: PublicClient,
  amount = maxUint256,
): Promise<string> {
  const { request } = await simulateContract(wagmiConfig, {
    abi: erc20Abi,
    address: tokenAddress,
    functionName: "approve",
    args: [spender, amount],
  });
  const hash = await writeContract(wagmiConfig, request);
  await waitForTransactionReceiptRecursion(client, hash);
  return hash;
}

export async function waitForTransactionReceiptRecursion(
  client: PublicClient,
  hash: `0x${string}`,
) {
  try {
    await waitForTransactionReceipt(client, {
      hash,
      timeout: MAX_TIMEOUT,
    });
  } catch (e) {
    if (
      (e as Error).message.includes(
        `Transaction receipt with hash "${hash}" could not be found. The Transaction may not be processed on a block yet.`,
      )
    ) {
      await sleep(1 * SECOND);
      await waitForTransactionReceiptRecursion(client, hash);
    } else {
      throw e;
    }
  }
}

export async function checkStrategyInsufficientAllowance(
  symbolInput: SymbolInput,
  strategyData: StrategyData,
  ethAddress: `0x${string}`,
  tokenInput: string,
) {
  const allowance = await getAllowance(
    symbolInput.address as Address,
    strategyData.strategyAddress as Address,
    ethAddress,
  );
  return Big(Amount.parse(tokenInput, symbolInput.decimals)).gt(
    allowance.toString(),
  );
}

export async function checkQueueWithdrawInsufficientAllowance(
  strategyAddress: Address,
  ethAddress: Address,
  shares: string,
) {
  if (!isDigit(shares)) return false;
  const allowance = await getAllowance(
    strategyAddress,
    strategyAddress,
    ethAddress,
  );
  return Big(shares).gt(allowance.toString());
}
