import { useTranslation } from "react-i18next";
import { BlocksecImg } from "../../assets";
import { AUDIT_LINK } from "../../constants";

function Audit() {
  const { t } = useTranslation();
  return (
    <div className="mt-20 scale-[0.8] sm:scale-100 flex justify-center items-center text-white text-opacity-75">
      <a
        href={AUDIT_LINK}
        target="_blank"
        className="flex justify-center items-center"
      >
        <p className="text-lg font-medium">{t("footer.auditedBy")}</p>
        <img src={BlocksecImg} />
      </a>
    </div>
  );
}

export default Audit;
