import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  AccountState,
  Network,
  WalletSelectorState,
} from "@near-wallet-selector/core";
import {
  setupModal,
  WalletSelectorModal,
} from "@near-wallet-selector/modal-ui";

import { setupMyNearWallet } from "@near-wallet-selector/my-near-wallet";
import { setupSender } from "@near-wallet-selector/sender";
import { setupWalletConnect } from "@near-wallet-selector/wallet-connect";
import { setupHereWallet } from "@near-wallet-selector/here-wallet";
import { setupMeteorWallet } from "@near-wallet-selector/meteor-wallet";
import { setupOKXWallet } from "@near-wallet-selector/okx-wallet";
import { setupBitteWallet } from "@near-wallet-selector/bitte-wallet";

import config from "../config";
// import { AlertModal, AlertModalType } from "../components/Modal/Modal";
import { isSignedInWithSender, signOut } from "../utils/wallet";
import { distinctUntilChanged, map } from "rxjs";
import AllstakeModal from "../components/modal";
import {
  MultiSendWalletSelector,
  setupMultiSendWalletSelector,
} from "allstake-sdk";
import { WALLET_CONNECT_METADATA } from "../constants";

interface WalletSelectorContextValue {
  selector: MultiSendWalletSelector;
  modal: WalletSelectorModal;
  accounts: Array<AccountState>;
  accountId: string | null;
}

export const WalletSelectorContext =
  React.createContext<WalletSelectorContextValue | null>(null);

export const WalletSelectorContextProvider = ({
  children,
}: PropsWithChildren) => {
  const [selector, setSelector] = useState<MultiSendWalletSelector | null>(
    null,
  );
  const [modal, setModal] = useState<WalletSelectorModal | null>(null);
  const [accounts, setAccounts] = useState<Array<AccountState>>([]);
  const [showAlertModal, setShowAlertModal] = useState<boolean>(false);
  const accountId =
    accounts.find((account) => account.active)?.accountId || null;
  const contractId =
    config.allstakeSdkOptions.near.contracts.strategyManagerContractId;
  const init = useCallback(async () => {
    if (!config.near || !contractId) return;
    const _selector = await setupMultiSendWalletSelector({
      network: config.near as Network,
      debug: process.env.REACT_APP_NEAR_ENV !== "production",
      modules: [
        setupMyNearWallet({
          walletUrl: config.near.walletUrl,
        }),
        setupSender(),
        setupMeteorWallet(),
        setupHereWallet(),
        setupOKXWallet(),
        setupBitteWallet(),
        setupWalletConnect({
          projectId: config.walletConnectProjectId,
          metadata: WALLET_CONNECT_METADATA,
          chainId: `near:${config.near.networkId}`,
        }) as any,
      ],
    });
    const _modal = setupModal(_selector, {
      contractId: contractId,
    });
    const state = _selector.store.getState();
    window.selector = _selector;
    window.modal = _modal;

    setAccounts(state.accounts);
    setSelector(_selector);
    setModal(_modal);
  }, []);

  useEffect(() => {
    init().catch((err) => {
      console.error(err);
      alert("Failed to initialise wallet selector");
    });
  }, [init]);

  useEffect(() => {
    // check whether installed sender browser extension and login status
    if (accountId && isSignedInWithSender()) {
      (window.near as any).request({ method: "status" }).then((status: any) => {
        const chainId = status.response.chain_id;
        const networkId = config.near?.networkId;
        if (
          (chainId === "mainnet" && networkId === "testnet") ||
          (chainId === "testnet" && networkId === "mainnet")
        ) {
          setShowAlertModal(true);
        }
      });
    }
  }, [accountId]);

  useEffect(() => {
    if (selector) {
      selector.wallet().then((wallet) => {
        window.allstake_near_wallet_id = wallet.id;
      });
    } else {
      window.allstake_near_wallet_id = undefined;
    }
  }, [selector, accountId]);

  useEffect(() => {
    if (!selector) {
      return;
    }

    const subscription = selector.store.observable
      .pipe(
        map((state: WalletSelectorState) => state.accounts),
        distinctUntilChanged(),
      )
      .subscribe((nextAccounts: AccountState[]) => {
        setAccounts(nextAccounts);
      });

    return () => subscription.unsubscribe();
  }, [selector]);

  if (!selector || !modal) {
    return null;
  }

  return (
    <WalletSelectorContext.Provider
      value={{
        selector,
        modal,
        accounts,
        accountId,
      }}
    >
      {children}
      {showAlertModal && (
        <AllstakeModal
          varient="warn"
          onRequestClose={() => {
            setShowAlertModal(false);
            signOut();
          }}
          title="modal.alert"
          description="modal.networkWrong"
          buttons={[
            {
              varient: "primary",
              text: "modal.gotIt",
              onClick: () => {
                setShowAlertModal(false);
                signOut();
              },
            },
          ]}
        />
      )}
    </WalletSelectorContext.Provider>
  );
};

export function useWalletSelector() {
  const context = useContext(WalletSelectorContext);

  if (!context) {
    throw new Error(
      "useWalletSelector must be used within a WalletSelectorContextProvider",
    );
  }

  return context;
}
