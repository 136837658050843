export function toShortAddress(address: string) {
  if (address && address.length > 21) {
    const splits = address.split(".");
    const prefix =
      address.substring(0, 5) +
      "..." +
      splits[0].substring(splits[0].length - 5);
    if (address.includes(".")) {
      return prefix + "." + splits.splice(1).join(".");
    } else return prefix;
  } else {
    return address;
  }
}
