import * as HoverCard from "@radix-ui/react-hover-card";
import { ExclamationMarkImg } from "../../assets";
import { twc } from "react-twc";
import { PropsWithChildren } from "react";

function AllStakeTips({
  children,
  trigger,
}: PropsWithChildren & {
  trigger?: JSX.Element;
}) {
  return (
    <HoverCard.Root openDelay={0} closeDelay={0}>
      <HoverCard.Trigger asChild>
        {trigger ?? (
          <img
            className="cursor-pointer"
            src={ExclamationMarkImg}
            width={16}
            height={16}
          />
        )}
      </HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCard.Content sideOffset={20}>
          <CardInfo>{children}</CardInfo>
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
}

const CardInfo = twc.div`
  border border-[#1F242F] bg-[#0C111D]
  rounded-md p-3 text-sm
  max-w-[300px]
`;

export default AllStakeTips;
