import { twc } from "react-twc";
import RestakingForm from "./RestakingForm";
import AllStakeTips from "../tips";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import AssetCollapseContext from "../../context/AssetCollapseContext";

const { useAssetCollapseTracked } = AssetCollapseContext;
function MeshedRestakingForm() {
  const [assetCollapse] = useAssetCollapseTracked();
  return (
    <MeshedRestakingFormContainer
      className={clsx(assetCollapse ? "mt-[100px]" : "mt-[40px]")}
    >
      <MeshedTitleArea />
      <RestakingForm />
    </MeshedRestakingFormContainer>
  );
}

const MeshedTitleArea = () => {
  const { t } = useTranslation();
  return (
    <MeshedTitleAreaStyle>
      <MeshedTitle>{t("restake.meshedRestakingDeposits")}</MeshedTitle>
      <MeshedDescription>
        {t("restake.depositToEarnRestakedPoints")}
        <AllStakeTips>
          {t("restake.depositToEarnRestakedPointsTip")}
        </AllStakeTips>
      </MeshedDescription>
    </MeshedTitleAreaStyle>
  );
};

const MeshedRestakingFormContainer = twc.div`
  flex flex-col gap-8
  justify-center items-center
`;

const MeshedTitleAreaStyle = twc.div`
  flex flex-col gap-4
  text-center
`;

const MeshedTitle = twc.div`
  text-[40px] font-semibold
`;

const MeshedDescription = twc.div`
  text-white text-opacity-60
  flex justify-center items-center gap-2
  cursor-pointer
`;

export default MeshedRestakingForm;
