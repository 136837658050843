import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/layout";
import Restake from "./pages/restake";
import Portfolio from "./pages/portfolio";

function _Router() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Restake />} />
          <Route path="/portfolio" element={<Portfolio />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default _Router;
