import {
  HeroContainer,
  HeroSubValue,
  HeroTitle,
  HeroValue,
} from "../../components/hero";
import YourRestakedAmount from "../../components/your-restaked-amount";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import { twc } from "react-twc";
import { EthImg, NearImg, SolanaImg } from "../../assets";
import { useWalletSelector } from "../../context/WalletSelectorContext";
import usePoints from "../../hooks/usePoints";
import config from "../../config";
import { SymbolInput } from "../../config/type";
import { useLocation, useNavigate } from "react-router-dom";
import AllStakeTips from "../../components/tips";
import toLocaleString from "../../utils/toLocaleString";
import { useTranslation } from "react-i18next";
import { useAccount, useWalletClient } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useEffect } from "react";
import useQuery from "../../hooks/useQuery";
import { isChainNativeToken } from "../../utils/regex";
import useChain from "../../hooks/useChain";
import { resetWagmiState } from "../../utils/wagmi";

function Portfolio() {
  const chain = useChain();
  const points = usePoints();
  const { t } = useTranslation();
  const query = useQuery();
  const name = query.get("name");
  const navigate = useNavigate();
  useEffect(() => {
    if (name) {
      if (
        isChainNativeToken(name) ||
        !config.restakingTokens.find((token) => token.name === name)
      ) {
        navigate("/portfolio");
      }
    }
  }, [name]);
  return (
    <>
      <HeroContainer>
        <HeroTitle>
          {t("portfolio.title", {
            network: (function () {
              switch (chain.chainId) {
                case "btc":
                  return t("chain.bitcoinMainnet");
                case "eth":
                  return t("chain.ethereumMainnet");
                case "near":
                  return t("chain.nearProtocol");
                case "solana":
                  return t("chain.solanaNetwork");
              }
            })(),
          })}
        </HeroTitle>
        <HeroValue>
          <p>
            {points.points} {t("portfolio.points")}
          </p>
          {Object.keys(points.tokens).length > 0 && (
            <AllStakeTips>
              <div className="flex flex-col gap-2">
                {Object.keys(points.tokens)
                  .map((addr) => ({
                    addr: addr,
                    points: points.tokens[addr],
                  }))
                  .filter(
                    (token) =>
                      config.restakingTokens.find(
                        (x) => x.address === token.addr,
                      )?.symbol,
                  )
                  .map((token, idx) => (
                    <p key={idx}>
                      {t("portfolio.pointsTip", {
                        amount: toLocaleString(token.points, 2),
                        symbol: config.restakingTokens.find(
                          (x) => x.address === token.addr,
                        )?.symbol,
                      })}
                    </p>
                  ))}
              </div>
            </AllStakeTips>
          )}
        </HeroValue>
        <HeroSubValue>{t("portfolio.subTitle")}</HeroSubValue>
        {!chain.isSignedIn && <LoginModal />}
        {chain.isSignedIn && <YourRestakedAmount />}
      </HeroContainer>
    </>
  );
}

const LoginModal = () => {
  const solanaWaletModal = useWalletModal();
  const { modal: nearWalletModal } = useWalletSelector();
  const navigate = useNavigate();
  const location = useLocation();
  const { open: ethModalOpen } = useWeb3Modal();
  const ethAccount = useAccount();
  const { data: walletClient } = useWalletClient();
  const { t } = useTranslation();
  const onClickLogin = (chainId: string, callback: () => void) => {
    const token =
      config.restakingTokens.find(
        (token: SymbolInput) => token.chain === chainId,
      ) ?? config.restakingTokens[0];
    navigate(`${location.pathname}?symbol=${token.name}`);
    callback();
  };
  const chain = useChain();

  return (
    <LoginModalStyle>
      {!chain.isSignedIn && chain.chainId === "near" && (
        <LoginButton
          onClick={() => onClickLogin("near", () => nearWalletModal.show())}
        >
          <img src={NearImg} width={24} height={24} />
          <p>
            {t("header.connectChainWallet", {
              chain: "Near",
            })}
          </p>
        </LoginButton>
      )}
      {!chain.isSignedIn && chain.chainId === "solana" && (
        <LoginButton
          onClick={() =>
            onClickLogin("solana", () => solanaWaletModal.setVisible(true))
          }
        >
          <img src={SolanaImg} width={24} height={24} />
          <p>
            {t("header.connectChainWallet", {
              chain: "Solana",
            })}
          </p>
        </LoginButton>
      )}
      {!chain.isSignedIn && chain.chainId === "eth" && (
        <LoginButton
          onClick={() =>
            onClickLogin("eth", () => {
              if (!walletClient && ethAccount.isConnected) {
                // reset wagmi state
                resetWagmiState();
              }
              ethModalOpen();
            })
          }
        >
          <img src={EthImg} width={24} height={24} />
          <p>
            {t("header.connectChainWallet", {
              chain: "Eth",
            })}
          </p>
        </LoginButton>
      )}
    </LoginModalStyle>
  );
};

const LoginButton = twc.button`
  text-lg text-white text-opacity-80 font-semibold
  flex justify-center items-center gap-2
  h-[56px] w-full rounded-full
  border border-white border-opacity-10
  bg-[#1F2636]
`;

const LoginModalStyle = twc.div`
  my-[20vh] w-full md:w-[700px] 
  flex flex-col gap-6
`;

export default Portfolio;
