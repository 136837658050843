import { Transaction } from "allstake-sdk";
import { getNear } from "./wallet";
import flattenDeep from "lodash/flattenDeep";
import { JsonRpcProvider } from "near-api-js/lib/providers";
import config from "../config";
import { isChainNativeToken } from "./regex";

export async function getMethodNamesFromArgs(
  txHashes: string[],
  accountId: string,
): Promise<string[]> {
  const near = await getNear();
  const methodNames = [];
  for (const txHash of txHashes) {
    const {
      transaction,
    }: {
      status?: { SuccessValue: string };
      transaction: Transaction & {
        receiver_id: string | undefined;
      };
    } = await (near.connection.provider as JsonRpcProvider).sendJsonRpc(
      "EXPERIMENTAL_tx_status",
      [txHash, accountId],
    );
    if (transaction && transaction.actions && transaction.actions.length > 0) {
      methodNames.push(
        transaction.actions.map((action: any) => {
          const methodName = action.FunctionCall.method_name;
          if (["ft_transfer_call", "deposit_and_stake"].includes(methodName)) {
            const receiverId = transaction.receiver_id;
            const token = config.restakingTokens.find(
              (token) =>
                token.address === receiverId && !isChainNativeToken(token.name),
            );
            if (token && receiverId) {
              return [[methodName, token.name].join(",")];
            }
          }

          return [methodName];
        }),
      );
    }
  }
  return flattenDeep(methodNames) as string[];
}
