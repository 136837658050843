import { useState } from "react";
import { createContainer } from "react-tracked";

const useRefreshBalance = () => useState<boolean>(false);

const {
  Provider: RefreshBalanceProvider,
  useTracked: useRefreshBalanceTracked,
} = createContainer(useRefreshBalance);

export default {
  RefreshBalanceProvider,
  useRefreshBalanceTracked,
};
