import { Connection, Keypair } from "@solana/web3.js";
import * as splToken from "@solana/spl-token-0.4.x";
import axios from "axios";
import * as web3 from "@solana/web3.js";
import Big from "big.js";
import { SECOND } from "./time";

export const solanaRpcUrl = ["production", "staging"].includes(
  import.meta.env.VITE_ALLSTAKE_ENV,
)
  ? import.meta.env.VITE_SOLANA_MAINNET_RPC_URL ??
    "https://api.mainnet-beta.solana.com"
  : import.meta.env.VITE_SOLANA_DEVNET_RPC_URL ??
    "https://api.devnet.solana.com";
export const connection = new Connection(solanaRpcUrl, {
  confirmTransactionInitialTimeout: 120 * SECOND,
});

interface TokenAccount {
  address: string;
  mint: string;
  owner: string;
  amount: number;
  delegated_amount: number;
  frozen: boolean;
}

interface TokenResponse {
  total: number;
  limit: number;
  page: number;
  token_accounts: TokenAccount[];
}

export async function getTokenAccounts(
  ownerAddress: string,
  tokenAddresses: string[],
): Promise<TokenAccount[]> {
  const response0 = await axios<{
    result: TokenResponse;
  }>({
    url: solanaRpcUrl,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: {
      jsonrpc: "2.0",
      id: 1,
      method: "getTokenAccounts",
      params: {
        page: 1,
        limit: 1000,
        displayOptions: {
          showZeroBalance: false,
        },
        owner: ownerAddress,
      },
    },
  });
  return tokenAddresses.map((tokenAddress) => {
    const token = response0.data.result?.token_accounts?.find(
      (ta) => ta.mint === tokenAddress,
    );
    if (!token) {
      return {
        address: Keypair.generate().publicKey.toBase58(), // just a mock address
        mint: tokenAddress,
        owner: ownerAddress,
        amount: 0,
        delegated_amount: 0,
        frozen: false,
      } as TokenAccount;
    }
    return token;
  });
}

export async function getTokenDecimals(tokenMintAddress: string) {
  // Create a PublicKey from the token mint address
  const mintPublicKey = new web3.PublicKey(tokenMintAddress);

  // Get the mint information
  const mintInfo = await splToken.getMint(connection, mintPublicKey);

  // Return the decimals
  return mintInfo.decimals;
}

export async function getTokenAmount(
  tokenAddress: string,
  userAddress: string,
) {
  const [tokens, tokenDecimals] = await Promise.all([
    getTokenAccounts(userAddress, [tokenAddress]),
    getTokenDecimals(tokenAddress),
  ]);
  return {
    uiAmount: Big(tokens[0].amount).div(Big(10).pow(tokenDecimals)).toNumber(),
    amount: tokens[0].amount,
  };
}

export async function getSolBalance(userAddress: string, isRaw?: boolean) {
  const publicKey = new web3.PublicKey(userAddress);
  const balance = await connection.getBalance(publicKey);

  if (isRaw) {
    return balance;
  } else {
    return balance / web3.LAMPORTS_PER_SOL;
  }
}
