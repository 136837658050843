import { useMemo } from "react";
import SymbolInputContext from "../context/SymbolInputContext";
import { useWalletSelector } from "../context/WalletSelectorContext";
import { useWallet } from "@solana/wallet-adapter-react";
import { useAccount, useWalletClient } from "wagmi";

export type ChainId = "solana" | "near" | "btc" | "eth";

const { useSymbolInputTracked } = SymbolInputContext;

function useChain() {
  const [symbolInput] = useSymbolInputTracked();
  const { selector, accountId } = useWalletSelector();
  const solanaWallet = useWallet();
  const ethAccount = useAccount();
  const { data: walletClient } = useWalletClient();

  const chain: {
    chainId?: ChainId;
    isSignedIn: boolean;
    address: string | null;
  } = useMemo(() => {
    if (symbolInput.chain === "near") {
      return {
        chainId: symbolInput.chain,
        isSignedIn: selector.isSignedIn(),
        address: accountId,
      };
    } else if (symbolInput.chain === "solana") {
      return {
        chainId: symbolInput.chain,
        isSignedIn: solanaWallet.connected,
        address: solanaWallet.publicKey?.toBase58() ?? null,
      };
    } else if (symbolInput.chain === "eth") {
      const isSignedIn = ethAccount.isConnected && !!walletClient;
      const address = isSignedIn ? (ethAccount.address as string) : null;
      return {
        chainId: symbolInput.chain,
        isSignedIn,
        address,
      };
    } else {
      return {
        chainId: undefined,
        isSignedIn: false,
        address: null,
      };
    }
  }, [symbolInput, selector.isSignedIn(), accountId, solanaWallet, ethAccount]);
  return chain;
}

export default useChain;
