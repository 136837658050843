import Big from "big.js";
import { AllstakeLogoImg } from "../assets";
import { ChainId } from "../hooks/useChain";

export const TGas = Big(10).pow(12);
export const MIN_SHOWN_NUMBER = 0.0001;
export const WALLET_CONNECT_METADATA = {
  name: "Allstake",
  description: "Omnichain Restaking, Trustless Scaling.",
  url: window.location.protocol + "//" + window.location.host,
  icons: [AllstakeLogoImg],
};
export const DELAYED_UNSTAKE_INTERVAL = 2;
export const NEAR_BALANCE_GAS_RESERVATION = 0.05;
export const SOL_BALANCE_GAS_RESERVATION = 0.05;
export const UNSTAKE_INTERVAL = 2;
export const ICON_PAGE_SIZE = 9;
export const TVL_MIN_PRECISION = 0.01;

export const AUDIT_LINK = "https://docs.allstake.org/security/audits";

export const apiChainMapping: Record<Exclude<ChainId, undefined>, string> = {
  solana: "solana",
  near: "near",
  btc: "btc",
  eth: "ethereum",
};

export const CHAIN_CONNECTION_SYMBOL = "~";
export const WAGMI_STORAGE_KEY = "ALLSTAKE_WAGMI_STORAGE";
