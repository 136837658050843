import { AllstakeSdk, BigNumber } from "allstake-sdk";
import config from "../config";

export async function getPriceInUsd(
  sdk: AllstakeSdk,
  address: string,
  symbol: string,
): Promise<BigNumber | null> {
  const token = config.restakingTokens.find((x) => x.address === address);
  try {
    if (symbol.startsWith("PT-")) {
      return token
        ? await sdk.dataService.getPtTokenPriceInUsd(symbol, token.decimals)
        : null;
    } else if (["NEAR", "SOL"].includes(symbol)) {
      return await sdk.dataService.getPriceInUsd(symbol.toUpperCase());
    } else if (
      config.restakingTokens.find((x) => x.symbol === "LiNEAR")?.address ===
      address
    ) {
      return await sdk.dataService.getLinearPriceInUsd(address);
    } else if (
      config.restakingTokens.find((x) => x.symbol === "stNEAR")?.address ===
      address
    ) {
      return await sdk.dataService.getStnearPriceInUsd(address);
    } else if (token?.chain) {
      return await sdk.dataService.getPriceInUsd(address);
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function getPairPrice(
  sdk: AllstakeSdk,
  addressA: string,
  symbolA: string,
  addressB: string,
  symbolB: string,
) {
  const [aPriceInUsd, bPriceInUsd] = await Promise.all([
    getPriceInUsd(sdk, addressA, symbolA),
    getPriceInUsd(sdk, addressB, symbolB),
  ]);
  if (!aPriceInUsd || !bPriceInUsd || bPriceInUsd.eq(0)) {
    return BigNumber(0);
  }
  return aPriceInUsd.div(bPriceInUsd);
}
