import { twc } from "react-twc";
import GalleryCard from "./GalleryCard";
import GalleryCardsContext from "../../context/GalleryCardsContext";
import { useMemo, useState } from "react";
import clsx from "clsx";
import { GalleryLeftArrowImg, GalleryRightArrowImg } from "../../assets";
import Marquee from "react-fast-marquee";
import { Slide } from "react-slideshow-image";
import chunk from "lodash/chunk";
import useScreenSize from "../../hooks/useScreenSize";
import AssetCollapseContext from "../../context/AssetCollapseContext";
import { ICON_PAGE_SIZE } from "../../constants";

const { useGalleryCardsTracked } = GalleryCardsContext;
const { useAssetCollapseTracked } = AssetCollapseContext;
function GalleryCards({ className }: { className?: string }) {
  const [galleryCards] = useGalleryCardsTracked();
  const size = useScreenSize();
  const chunkValue = useMemo(() => {
    switch (size) {
      case "2xl":
        return 4;
      case "xl":
      case "lg":
        return 3;
      case "md":
      case "sm":
        return 2;
    }
  }, [size]);
  const chunkCards = useMemo(
    () => chunk(galleryCards, chunkValue),
    [chunkValue, galleryCards],
  );
  const [shownChunkIdx, setShownChunkIdx] = useState(0);
  const disabledPrev = shownChunkIdx === 0;
  const disabledNext = shownChunkIdx === chunkCards.length - 1;
  return (
    <OuterCardsAbsoluteContainer className={clsx(className)}>
      <Slide
        transitionDuration={500}
        infinite={false}
        indicators={false}
        arrows={true}
        autoplay={false}
        prevArrow={
          <PrevButton
            className={clsx(
              disabledPrev ? "!cursor-default" : "!cursor-pointer",
            )}
            disabled={disabledPrev}
          >
            <img src={GalleryLeftArrowImg} />
          </PrevButton>
        }
        nextArrow={
          <NextButton
            className={clsx(
              disabledNext ? "!cursor-default" : "!cursor-pointer",
            )}
            disabled={disabledNext}
          >
            <img src={GalleryRightArrowImg} />
          </NextButton>
        }
        onChange={(_, to) => {
          setShownChunkIdx(to);
        }}
      >
        {chunkCards.map((row, idx) => {
          return (
            <GalleryCardsStyle key={idx}>
              {row.map((props, idx) => (
                <GalleryCard key={idx} {...props} />
              ))}
            </GalleryCardsStyle>
          );
        })}
      </Slide>
    </OuterCardsAbsoluteContainer>
  );
}

export function CoinList({ className }: { className?: string }) {
  const [galleryCards] = useGalleryCardsTracked();
  const coinIcons = useMemo(() => {
    return galleryCards.map((card) => card.icon);
  }, [galleryCards]);
  const play = coinIcons.length > ICON_PAGE_SIZE;
  const coinIconsContent = coinIcons.map((coinIcon, idx) => (
    <img className={"w-10 h-10 mr-6"} key={idx} src={coinIcon} />
  ));
  const [, setAssetCollapse] = useAssetCollapseTracked();
  return (
    <OuterCoinsAbsoluteContainer className={clsx(className)}>
      <CoinListStyle onClick={() => setAssetCollapse(false)}>
        {play ? (
          <Marquee play={play}>{coinIconsContent}</Marquee>
        ) : (
          <CenterLayout>{coinIconsContent}</CenterLayout>
        )}
      </CoinListStyle>
    </OuterCoinsAbsoluteContainer>
  );
}

const CenterLayout = twc.div`
  w-full flex justify-center
`;

const commonInnerStyle = `
  max-w-[1024px] m-auto
  flex items-center
`;

const commonOuterStyle = `
  absolute w-full h-auto
`;

// 576 = (40 + 24) * 9 = (icon width + gap) * max number
const CoinListStyle = twc.div`
  ${commonInnerStyle} justify-start
  w-[576px] overflow-hidden cursor-pointer
`;

const GalleryCardsStyle = twc.div`
  ${commonInnerStyle} justify-center gap-10
`;

const OuterCardsAbsoluteContainer = twc.div`
  ${commonOuterStyle} top-[380px] translate-y-[-50%] duration-300
`;

const OuterCoinsAbsoluteContainer = twc.div`
  ${commonOuterStyle} top-[240px] duration-300
`;

const commonControllerStyle = `
  absolute top-[50%] translate-y-[-50%]
`;
const PrevButton = twc.button`
  ${commonControllerStyle} 
  ml-[5%] disabled:opacity-0 
`;

const NextButton = twc.button`
  ${commonControllerStyle} 
  mr-[5%] duration-300
  disabled:opacity-0
`;
export default GalleryCards;
