import { useState } from "react";
import { createContainer } from "react-tracked";
import config from "../config";

const useSelectedNetwork = () =>
  useState<Array<string>>(config.dropdownNetworks);

const {
  Provider: SelectedNetworkProvider,
  useTracked: useSelectedNetworkTracked,
} = createContainer(useSelectedNetwork);

export default {
  SelectedNetworkProvider,
  useSelectedNetworkTracked,
};
