import clsx from "clsx";
import { PropsWithChildren } from "react";

function BoostTag({ children }: PropsWithChildren) {
  return (
    <div
      style={{
        background: "linear-gradient(91.59deg, #FF7613 0%, #E99330 100%)",
      }}
      className={clsx(
        "px-[6px] py-[2.5px] rounded-[4px]",
        "text-xs font-medium text-white",
      )}
    >
      {children}
    </div>
  );
}

export default BoostTag;
