import { useState } from "react";
import { createContainer } from "react-tracked";

export interface BalanceContextType {
  tokenBalance: string;
  stakedToken: string;
}

const useBalance = () => useState<Record<string, BalanceContextType>>({});

const { Provider: BalanceProvider, useTracked: useBalanceTracked } =
  createContainer(useBalance);

export default {
  BalanceProvider,
  useBalanceTracked,
};
