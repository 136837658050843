import { useTranslation } from "react-i18next";
import { twc } from "react-twc";

function Footer() {
  const { t } = useTranslation();
  return (
    <FooterStyle>
      {t("footer.copyright", {
        date: new Date().getFullYear(),
      })}
    </FooterStyle>
  );
}

const FooterStyle = twc.footer`
  py-6
  flex justify-center items-center
  text-sm text-white text-opacity-60
`;

export default Footer;
