import axios from "axios";
import toLocaleString from "../utils/toLocaleString";
import config from "../config";
import useSWR from "swr";
import { apiChainMapping } from "../constants";
import useChain, { ChainId } from "./useChain";
interface PointsResponseData {
  total: string;
  tokens: Record<string, string>;
}

async function getPoints({
  chainId,
  address,
}: {
  chainId?: ChainId;
  address: string | null;
}): Promise<PointsResponseData | null> {
  if (!address || !chainId) return null;
  const url = new URL(
    `points/${apiChainMapping[chainId]}/${address}`,
    config.pointsApiUrl,
  );
  const pointsUrl = url.href;
  return await axios
    .get(
      pointsUrl,
      config.pointsApiUrl.includes("ngrok")
        ? {
            headers: {
              "ngrok-skip-browser-warning": "true",
            },
          }
        : undefined,
    )
    .then((response) => {
      const data: PointsResponseData = response.data?.data;
      if (data) {
        return {
          total: toLocaleString(data.total, 2),
          tokens: data.tokens,
        };
      } else {
        return null;
      }
    });
}

function usePoints() {
  const chain = useChain();
  const { data } = useSWR(`getPoints_${chain.chainId}_${chain.address}`, () =>
    getPoints({
      chainId: chain.chainId,
      address: chain.address,
    }),
  );
  return {
    points: data?.total ?? "-",
    tokens: data?.tokens ?? {},
  };
}

export default usePoints;
