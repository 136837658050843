import { useEffect } from "react";
import { twc } from "react-twc";
import {
  CircleBgImg,
  CloseImg,
  FailedImg,
  LoadingCircleImg,
  SuccessImg,
  WarnImg,
} from "../../assets";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import LoadingCircle from "../loading/LoadingCircle";

const ContainerOuter = twc.div`
  absolute z-50 
  w-full h-screen overflow-hidden
  top-0 left-0 bg-[#1F242F] bg-opacity-60 backdrop-blur-xl
  flex justify-center items-center
  px-4
  font-inter
`;

const ModalContainer = twc.div`
  p-6 relative overflow-hidden
  w-full sm:w-[400px]
`;

const LeftTopBgCircle = twc.img`
  absolute -z-10 left-0 top-0 pointer-events-none
`;

const LeftTopIcon = twc.img`
  mb-4 w-12 h-12 pointer-events-none
`;

const CloseIcon = twc.img`
  w-6 h-6
`;

const Title = twc.div`
  mb-1 text-lg font-semibold 
`;

const Description = twc.div`
  text-sm mb-8 text-[#94969C]
  break-words
`;

const PrimaryButton = twc.button`
  bg-[#4565E5] rounded-full text-base font-semibold
  w-full py-[10px] disabled:opacity-50 disabled:cursor-not-allowed
  flex justify-center items-center
`;

const OutlineButton = twc.button`
  bg-[#1F2636]
  border border-[#333741]
  rounded-full text-base font-semibold
  w-full py-[10px] disabled:opacity-50 disabled:cursor-not-allowed
  flex justify-center items-center
`;

const ButtonGroup = twc.div`
  flex gap-3
`;

function AllstakeModal({
  varient,
  onRequestClose,
  title,
  description,
  buttons,
  buttonGroupCol,
}: {
  onRequestClose: () => void;
  varient: "success" | "error" | "warn" | "loading";
  title: string;
  description: string;
  buttonGroupCol?: boolean;
  buttons: Array<{
    loading?: boolean;
    disabled?: boolean;
    varient: "primary" | "outline";
    text: string;
    onClick: () => void;
  }>;
}) {
  useEffect(() => {
    const innerHeight = window.innerHeight;
    window.scrollTo(0, 0); // scroll to top
    document.body.style.overflow = "hidden";
    return () => {
      window.scrollTo(0, innerHeight);
      document.body.style.overflow = "auto";
    };
  });
  const { t } = useTranslation();
  return (
    <ContainerOuter>
      <ModalContainer>
        {varient !== "loading" && <LeftTopBgCircle src={CircleBgImg} />}
        {varient === "success" && <LeftTopIcon src={SuccessImg} />}
        {varient === "error" && <LeftTopIcon src={FailedImg} />}
        {varient === "warn" && <LeftTopIcon src={WarnImg} />}
        <div
          className={clsx("absolute -z-20 inset-0", "bg-[#0C111D] rounded-xl")}
        />
        {varient !== "loading" && (
          <button
            className="absolute right-[26px] top-[26px]"
            onClick={onRequestClose}
          >
            <CloseIcon src={CloseImg} />
          </button>
        )}
        {varient === "loading" && (
          <div className="flex justify-center items-center">
            <img
              className="w-8 h-8 mt-4 mb-8 rotate-infinite"
              src={LoadingCircleImg}
            />
          </div>
        )}
        <Title dangerouslySetInnerHTML={{ __html: t(title) }} />
        <Description
          dangerouslySetInnerHTML={{ __html: t(description) }}
        ></Description>

        <ButtonGroup className={clsx(buttonGroupCol && "flex-col")}>
          {buttons.map((btn, idx) => {
            if (btn.varient === "primary") {
              return (
                <PrimaryButton
                  disabled={btn.disabled || btn.loading}
                  key={idx}
                  onClick={btn.onClick}
                >
                  {btn.loading ? <LoadingCircle /> : t(btn.text)}
                </PrimaryButton>
              );
            } else {
              return (
                <OutlineButton
                  disabled={btn.disabled || btn.loading}
                  key={idx}
                  onClick={btn.onClick}
                >
                  {btn.loading ? <LoadingCircle /> : t(btn.text)}
                </OutlineButton>
              );
            }
          })}
        </ButtonGroup>
      </ModalContainer>
    </ContainerOuter>
  );
}

export default AllstakeModal;
