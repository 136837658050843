import { useState } from "react";
import { createContainer } from "react-tracked";
import { SymbolInput } from "../config/type";
import config from "../config";

const useSymbolInput = () => useState<SymbolInput>(config.restakingTokens[0]);

const { Provider: SymbolInputProvider, useTracked: useSymbolInputTracked } =
  createContainer(useSymbolInput);

export default {
  useSymbolInputTracked,
  SymbolInputProvider,
};
