import { useWindowSize } from "react-use";

function useScreenSize(): "sm" | "md" | "lg" | "xl" | "2xl" {
  const { width } = useWindowSize();
  if (width < 640) return "sm";
  if (width < 768) return "md";
  if (width < 1024) return "lg";
  if (width < 1280) return "xl";
  return "2xl";
}
export default useScreenSize;
