import { twc } from "react-twc";

export const GetLink = twc.a`
  flex items-center gap-[2px]
  mt-3 text-[#4565E5] text-sm font-semibold
`;

export const TokenLabel = twc.div`
  text-sm mb-[6px]
`;

export const TokenInputContainer = twc.div`
  relative cursor-pointer
  p-4 flex items-center gap-2
  bg-[#1F2636] rounded-xl
  border border-white border-opacity-10 
  duration-300
  select-none
`;

export const TokenInputIcon = twc.img`
  w-5 h-5 duration-300
  rounded-full overflow-hidden
`;

export const TokenInputSelectText = twc.div`
  flex-1 bg-transparent outline-none
  text-opacity-40 text-white
`;

export const InputAreaContainer = twc.div`
  flex flex-col
`;

export const InputRow = twc.div`
  w-full flex justify-between items-center
`;
