import { WAGMI_STORAGE_KEY } from "../constants";

export function resetWagmiState() {
  const wagmiKeys = Array(localStorage.length)
    .fill("0")
    .map((_, i) => localStorage.key(i))
    .filter((key) => key?.startsWith(WAGMI_STORAGE_KEY));
  wagmiKeys.forEach(
    (key: string | null) => key && localStorage.removeItem(key),
  );
  const url = new URL(window.location.href);
  url.searchParams.set("modal", "eth-connect");
  window.location.href = url.href;
}
