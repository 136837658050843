import { useLocation, useNavigate } from "react-router-dom";
import { twc } from "react-twc";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import NetworkSwitcher from "../network-switcher";
import { useWalletSelector } from "../../context/WalletSelectorContext";
import { signOut } from "../../utils/wallet";
import { useWallet } from "@solana/wallet-adapter-react";
import { toShortAddress } from "../../utils/address";
import { ArrowDownImg, BrandImg, PowerSwitchImg } from "../../assets";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useEffect, useState } from "react";
import clsx from "clsx";
import useQuery from "../../hooks/useQuery";
import { useTranslation } from "react-i18next";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useWalletClient } from "wagmi";
import {
  extractToTokenNameByName,
  isChainNativeToken,
} from "../../utils/regex";
import useChain from "../../hooks/useChain";
import { useSearchParam } from "react-use";
import { resetWagmiState } from "../../utils/wagmi";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const chain = useChain();
  const solanaWalletModal = useWalletModal();
  const solanaWallet = useWallet();
  const { modal: nearWalletModal, accountId: nearAccountId } =
    useWalletSelector();
  const { open: ethModalOpen } = useWeb3Modal();
  const { data: walletClient } = useWalletClient();
  const ethAccount = useAccount();
  const query = useQuery();
  const _symbol = query.get("symbol");
  const symbol =
    _symbol && isChainNativeToken(_symbol)
      ? extractToTokenNameByName(_symbol)
      : _symbol;
  const symbolQuery = symbol ? `?symbol=${symbol}` : ``;
  const location = useLocation();
  const { t } = useTranslation();

  // handle eth modal open
  const modal = useSearchParam("modal");
  useEffect(() => {
    if (modal === "eth-connect") {
      ethModalOpen();
      const url = new URL(window.location.href);
      url.searchParams.delete("modal");
      navigate(`${url.pathname}${url.search}`);
    }
  }, [modal]);
  return (
    <HeaderStyle>
      <HeaderSection className="justify-start">
        <Brand />
      </HeaderSection>
      <HeaderSection className="hidden md:flex gap-6 justify-center">
        <HeaderMenuButton
          className={
            location.pathname === "/" &&
            "!text-opacity-80 hover:!text-opacity-80"
          }
          onClick={() => navigate(`/${symbolQuery}`)}
        >
          {t("header.restake")}
        </HeaderMenuButton>
        <HeaderMenuButton
          className={
            location.pathname === "/portfolio" &&
            "!text-opacity-80 hover:!text-opacity-80"
          }
          onClick={() => navigate(`/portfolio${symbolQuery}`)}
        >
          {t("header.portfolio")}
        </HeaderMenuButton>
        <HeaderMenuButton
          className={
            location.pathname === "/portfolio" &&
            "!text-opacity-80 hover:!text-opacity-80"
          }
          onClick={() => window.open("https://docs.allstake.org/", "_blank")}
        >
          {t("header.docs")}
        </HeaderMenuButton>
      </HeaderSection>
      <RowStack>
        <NetworkSwitcher />
        {!chain.isSignedIn && chain.chainId && (
          <SignInOutButton
            className="!justify-center"
            onClick={() => {
              if (chain.chainId === "near") {
                // near
                nearWalletModal.show();
              } else if (chain.chainId === "solana") {
                // solana
                solanaWalletModal.setVisible(true);
              } else if (chain.chainId === "eth") {
                // eth
                if (!walletClient && ethAccount.isConnected) {
                  // reset wagmi state
                  resetWagmiState();
                }
                ethModalOpen();
              }
            }}
          >
            {t("header.selectWallet")}
          </SignInOutButton>
        )}
        {chain.isSignedIn && chain.chainId === "near" && nearAccountId && (
          <AddressWithSignOut
            onOpenChange={(open) => setIsOpen(open)}
            isOpen={isOpen}
            address={nearAccountId}
            signOut={async () => {
              await signOut();
            }}
          />
        )}
        {chain.isSignedIn &&
          chain.chainId === "solana" &&
          solanaWallet.publicKey && (
            <AddressWithSignOut
              onOpenChange={(open) => setIsOpen(open)}
              isOpen={isOpen}
              address={solanaWallet.publicKey.toBase58()}
              signOut={async () => {
                await solanaWallet.disconnect();
              }}
            />
          )}
        {chain.isSignedIn && chain.chainId === "eth" && ethAccount.address && (
          <AddressWithSignOut
            onOpenChange={(open) => setIsOpen(open)}
            isOpen={isOpen}
            address={ethAccount.address}
            signOut={async () => {
              await ethModalOpen();
            }}
          />
        )}
      </RowStack>
    </HeaderStyle>
  );
}

function AddressWithSignOut({
  onOpenChange,
  isOpen,
  address,
  signOut,
}: {
  isOpen?: boolean;
  address: string;
  onOpenChange: (open: boolean) => void;
  signOut: () => void;
}) {
  const { t } = useTranslation();
  return (
    <DropdownMenu.Root onOpenChange={onOpenChange}>
      <DropdownMenu.Trigger
        asChild
        className={clsx(isOpen && "token-input-shadow")}
      >
        <SignInOutButton title={address}>
          {toShortAddress(address)}
          <img src={ArrowDownImg} width={20} height={20} />
        </SignInOutButton>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content className="w-full" sideOffset={20}>
          <DropdownContainer>
            <DropdownItem onClick={signOut}>
              <img src={PowerSwitchImg} width={24} height={24} />
              <p>{t("header.signOut")}</p>
            </DropdownItem>
          </DropdownContainer>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}

const DropdownItem = twc.div`
  flex items-center justify-start gap-2 py-3 px-2
  hover:bg-[#1F242F]
  rounded-md cursor-pointer
`;

const DropdownContainer = twc.div`
  w-full min-w-[160px]
  rounded-md
  bg-[#0C111D]
  border border-[#1F242F]
  p-2
  flex flex-col gap-2
`;

const SignInOutButton = twc.div`
  px-6 h-[44px] min-w-[160px]
  rounded-full
  bg-white bg-opacity-5
  cursor-pointer select-none
  flex justify-between items-center gap-3
  font-semibold
`;

const RowStack = twc.div`
  flex gap-5
`;

const HeaderMenuButton = twc.button`
  text-base font-semibold text-white text-opacity-60
  py-2 px-[10px] 
  hover:text-opacity-100 duration-300
`;

const HeaderSection = twc.section`
  flex items-center
  min-w-[300px]
`;

const Brand = () => (
  <a href="https://allstake.org/" target="_blank">
    <img src={BrandImg} width="auto" height={40} />
  </a>
);

const HeaderStyle = twc.header`
  py-[18px] px-10 flex justify-between items-center
`;

export default Header;
