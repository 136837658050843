import { TFunction } from "i18next";

export function formatToReadableError(
  e: any,
  t: TFunction<"translation", undefined>,
) {
  const message = getErrorMessage(e);
  if (e.name === "WalletSignTransactionError") {
    return `${t("error.errorFromWallet")}: ${e.message}`;
  }
  return message;
}

function getErrorMessage(e: any) {
  if (e instanceof Error) {
    try {
      const obj = JSON.parse(e.message);
      if (obj[0]?.kind?.ExecutionError) {
        return obj[0].kind.ExecutionError;
      } else {
        return e.message;
      }
    } catch {
      return e.message;
    }
  } else {
    return JSON.stringify(e);
  }
}
