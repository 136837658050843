import Big from "big.js";
import { MIN_SHOWN_NUMBER, TVL_MIN_PRECISION } from "../constants";
import { isDigit } from "./regex";
import toLocaleString from "./toLocaleString";
import { BigNumber } from "allstake-sdk";

export function formatTotalDepositAmount(totalDepositAmount: string) {
  if (
    Big(totalDepositAmount).gt(0) &&
    Big(totalDepositAmount).lt(TVL_MIN_PRECISION)
  ) {
    return `<${TVL_MIN_PRECISION}`;
  }
  return toLocaleString(totalDepositAmount, 2);
}

export function formatTvl(tvl: BigNumber | null, totalDepositAmount: string) {
  if (!tvl || !isDigit(totalDepositAmount)) {
    return "-";
  }

  const formattedTvl = tvl.times(totalDepositAmount).toNumber();
  if (formattedTvl > 0 && formattedTvl < TVL_MIN_PRECISION) {
    return `<${TVL_MIN_PRECISION}`;
  }

  return toShortNumber(formattedTvl);
}

export function toShortNumber(n: number): string {
  if (n > 1e9) return toLocaleString(n / 1e9, 2) + "B";
  if (n > 1e6) return toLocaleString(n / 1e6, 2) + "M";
  if (n > 1e3) return toLocaleString(n / 1e3, 2) + "K";
  return toLocaleString(n, 2);
}

export function formatShownDigit(balance?: string) {
  if (!balance) return "-";
  if (isDigit(balance)) {
    if (Big(balance).lt(MIN_SHOWN_NUMBER) && Big(balance).gt(0)) {
      return `< ${MIN_SHOWN_NUMBER}`;
    } else {
      return toLocaleString(balance, 4);
    }
  } else {
    return balance;
  }
}
