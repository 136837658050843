import { twc } from "react-twc";

export const HeroContainer = twc.div`
  pt-10 flex flex-col justify-center items-center gap-2
`;

export const HeroTitle = twc.div`
  text-base font-normal text-white text-opacity-60
`;

export const HeroValue = twc.div`
  text-[40px] font-medium 
  flex justify-center items-center
  gap-4
`;

export const HeroSubValue = twc.div`
  text-white text-opacity-40 text-sm mt-6
`;
