import { useState } from "react";
import { createContainer } from "react-tracked";
import config from "../config";

const useBalance = () => useState<Array<string>>(config.dropdownAssets);

const {
  Provider: SelectedAssetsProvider,
  useTracked: useSelectedAssetsTracked,
} = createContainer(useBalance);

export default {
  SelectedAssetsProvider,
  useSelectedAssetsTracked,
};
