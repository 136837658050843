import { PropsWithChildren, useEffect } from "react";
import SymbolInputContext from "./SymbolInputContext";
import RestakeTabContext from "./RestakeTabContext";
import TokenInputContext from "./TokenInputContext";
import BalanceContext from "./BalanceContext";
import useAllstakeSdk from "../hooks/useAllstakeSdk";
import { PublicKey } from "@solana/web3.js";
import { WalletContextState, useWallet } from "@solana/wallet-adapter-react";
import { AllstakeSdk, Amount, BN } from "allstake-sdk";
import RefreshBalanceContext from "./RefreshBalanceContext";
import { useSearchParams } from "react-router-dom";
import { useWalletSelector } from "./WalletSelectorContext";
import {
  getNearTokenBalanceCombine,
  getSolTokenBalanceCombine,
} from "../utils/wallet";
import config from "../config";
import { SymbolInput } from "../config/type";
import useQuery from "../hooks/useQuery";
import { SECOND } from "../utils/time";
import { useAccount } from "wagmi";
import GalleryCardsContext from "./GalleryCardsContext";
import { useGalleryGards } from "../hooks/useGalleryCards";
import { getErc20Balance } from "../utils/erc20";
import { Address } from "viem";
import AssetCollapseContext from "./AssetCollapseContext";
import { sharesToBalance } from "allstake-sdk/dist/ethereum";
import SelectedAssets from "./SelectedAssets";
import SelectedNetwork from "./SelectedNetwork";
import useChain from "../hooks/useChain";

const { GalleryCardsProvider, useGalleryCardsTracked } = GalleryCardsContext;
const { SymbolInputProvider, useSymbolInputTracked } = SymbolInputContext;
const { RestakeTabProvider, useRestakeTabTracked } = RestakeTabContext;
const { TokenInputProvider } = TokenInputContext;
const { BalanceProvider, useBalanceTracked } = BalanceContext;
const { RefreshBalanceProvider, useRefreshBalanceTracked } =
  RefreshBalanceContext;
const { AssetCollapseProvider } = AssetCollapseContext;
const { SelectedAssetsProvider } = SelectedAssets;
const { SelectedNetworkProvider, useSelectedNetworkTracked } = SelectedNetwork;

async function getUserDepositAmount(
  allstakeSdk: AllstakeSdk,
  symbolInput: SymbolInput,
  wallet: WalletContextState,
) {
  if (
    !allstakeSdk.solana ||
    !allstakeSdk.solana.strategyManagerProgram ||
    !wallet.publicKey
  ) {
    return new BN(0);
  }
  try {
    return await allstakeSdk.solana.strategyManagerProgram.getUserDepositAmount(
      new PublicKey(symbolInput.address),
      wallet.publicKey,
    );
  } catch (e) {
    console.error(e);
    return new BN(0);
  }
}

function ContextInitializer({ children }: PropsWithChildren) {
  const { address } = useAccount();

  const allstakeSdk = useAllstakeSdk();
  const solanaWallet = useWallet();
  const [balance, setBalance] = useBalanceTracked();
  const [symbolInput, setSymbolInput] = useSymbolInputTracked();
  const [, setTab] = useRestakeTabTracked();
  const [refreshBalance, setRefreshBalance] = useRefreshBalanceTracked();
  const { selector, accountId } = useWalletSelector();
  const ethAccount = useAccount();
  const query = useQuery();

  const [, setGalleryCards] = useGalleryCardsTracked();
  const galleryCardsInHooks = useGalleryGards();

  useEffect(() => {
    setGalleryCards(galleryCardsInHooks);
  }, [galleryCardsInHooks]);

  const chain = useChain();
  const [, setSelectedNetwork] = useSelectedNetworkTracked();
  useEffect(() => {
    if (chain.isSignedIn && symbolInput.chain) {
      setSelectedNetwork([symbolInput.chain]);
    } else {
      setSelectedNetwork(config.dropdownNetworks);
    }
  }, [chain.isSignedIn]);

  //check sign in
  useEffect(() => {
    let token =
      config.restakingTokens.find((token) =>
        config.supportNetworks.includes(token.chain),
      ) ?? config.restakingTokens[0];
    const symbol = query.get("symbol");
    if (symbol) {
      const findToken = config.restakingTokens.find(
        (token: SymbolInput) => token.name === symbol,
      );
      if (findToken) {
        token = findToken;
      }
    }
    setSymbolInput(token);
  }, [
    selector,
    accountId,
    solanaWallet.publicKey,
    ethAccount.address,
    query.get("symbol"),
  ]);

  async function _refreshBalance() {
    if (symbolInput.chain === "solana") {
      if (
        !allstakeSdk ||
        !allstakeSdk.solana ||
        !allstakeSdk.solana.strategyManagerProgram ||
        !solanaWallet.publicKey
      ) {
        return;
      }
      try {
        const [uiAmount, depositAmount] = await Promise.all([
          getSolTokenBalanceCombine(
            solanaWallet.publicKey.toBase58(),
            symbolInput,
          ),
          getUserDepositAmount(allstakeSdk, symbolInput, solanaWallet),
        ]);
        const tokenBalance = String(uiAmount);
        return {
          tokenBalance,
          stakedToken: Amount.format(
            depositAmount.toString(),
            symbolInput.decimals,
          ),
        };
      } catch (e) {
        console.error(e);
        return {
          tokenBalance: "-",
          stakedToken: "-",
        };
      }
    } else if (symbolInput.chain === "near") {
      if (
        !allstakeSdk ||
        !allstakeSdk.near ||
        !allstakeSdk.near.strategyManagerContract ||
        !accountId
      ) {
        return;
      }
      const [balance, deposits] = await Promise.all([
        getNearTokenBalanceCombine(accountId, symbolInput),
        allstakeSdk.near.strategyManagerContract.getDeposits({
          staker: accountId,
        }),
      ]);
      const depositRaw = deposits.find(
        ([strategy]) => strategy.underlying_token === symbolInput.address,
      );
      const deposit = depositRaw ? depositRaw[1] : "0";
      const tokenBalance = balance;
      const stakedToken = Amount.format(deposit, symbolInput.decimals);
      return {
        tokenBalance,
        stakedToken,
      };
    } else if (symbolInput.chain === "eth") {
      if (
        !address ||
        !allstakeSdk ||
        !allstakeSdk.ethereum ||
        !allstakeSdk.ethereum.strategyManagerContract
      ) {
        return;
      }
      const [balance, userStrategyData, strategyData] = await Promise.all([
        getErc20Balance(symbolInput.address as Address, address as Address),
        allstakeSdk.ethereum.uiDataProviderContract.userStrategyData(
          symbolInput.address,
          address,
        ),
        allstakeSdk.ethereum.uiDataProviderContract.strategyDataByToken(
          symbolInput.address,
        ),
      ]);
      const strategyTotalBalance = await getErc20Balance(
        symbolInput.address as Address,
        userStrategyData.strategy as Address,
      );
      const shares = userStrategyData.userShares;
      const tokenBalance = Amount.format(
        balance.toString(),
        symbolInput.decimals,
      );
      const stakedTokenRaw = sharesToBalance(shares, {
        strategyTotalBalance,
        strategyTotalShares: strategyData.strategyTotalShares,
      });
      const stakedToken = Amount.format(
        stakedTokenRaw.toString(),
        symbolInput.decimals,
      );
      return {
        tokenBalance,
        stakedToken,
      };
    }
  }
  useEffect(() => {
    if (refreshBalance) {
      _refreshBalance().then((newBalance) => {
        if (
          newBalance &&
          (newBalance.stakedToken !==
            balance[symbolInput.address]?.stakedToken ||
            newBalance.tokenBalance !==
              balance[symbolInput.address]?.tokenBalance)
        ) {
          setRefreshBalance(false);
          setBalance((prev) => ({
            ...prev,
            ...{
              [symbolInput.address]: newBalance,
            },
          }));
        } else {
          const interval = setInterval(() => {
            _refreshBalance().then((newBalance) => {
              if (!refreshBalance) {
                clearInterval(interval);
              }
              if (
                newBalance &&
                (newBalance.stakedToken !==
                  balance[symbolInput.address]?.stakedToken ||
                  newBalance.tokenBalance !==
                    balance[symbolInput.address]?.tokenBalance)
              ) {
                setRefreshBalance(false);
                setBalance((prev) => ({
                  ...prev,
                  ...{
                    [symbolInput.address]: newBalance,
                  },
                }));
                clearInterval(interval);
              }
            });
          }, 1 * SECOND);
        }
      });
    }
  }, [refreshBalance]);
  useEffect(() => {
    _refreshBalance().then((newBalance) => {
      if (newBalance) {
        setBalance((prev) => ({
          ...prev,
          ...{
            [symbolInput.address]: newBalance,
          },
        }));
      }
    });
  }, [
    symbolInput,
    allstakeSdk,
    accountId,
    solanaWallet.connected,
    ethAccount.address,
  ]);

  const [searchParams] = useSearchParams();
  const tabParam = searchParams.get("tab") || "deposit";
  useEffect(() => {
    if (tabParam === "deposit") {
      setTab("deposit");
    } else {
      setTab("unstake");
    }
  }, [tabParam, setTab]);
  return children;
}
function GlobalStorageContext({ children }: PropsWithChildren) {
  return (
    <SelectedAssetsProvider>
      <SelectedNetworkProvider>
        <AssetCollapseProvider>
          <SymbolInputProvider>
            <GalleryCardsProvider>
              <RestakeTabProvider>
                <TokenInputProvider>
                  <RefreshBalanceProvider>
                    <BalanceProvider>
                      <ContextInitializer>{children}</ContextInitializer>
                    </BalanceProvider>
                  </RefreshBalanceProvider>
                </TokenInputProvider>
              </RestakeTabProvider>
            </GalleryCardsProvider>
          </SymbolInputProvider>
        </AssetCollapseProvider>
      </SelectedNetworkProvider>
    </SelectedAssetsProvider>
  );
}

export default GlobalStorageContext;
