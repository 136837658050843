import { useState } from "react";
import { createContainer } from "react-tracked";
import { GalleryCards } from "../hooks/useGalleryCards";
import { BigNumber } from "allstake-sdk";
import config from "../config";
import { SymbolInput } from "../config/type";

const useGalleryCards = () =>
  useState<GalleryCards>(
    config.restakingTokens.map((token: SymbolInput) => {
      return {
        symbol: "-",
        icon: token.icon,
        title: `$-`,
        value: `-`,
        valueInUsd: "~$-",
        valueInUsdRaw: undefined,
        price: BigNumber(0),
        boostTag: token.boostTag,
      };
    }),
  );

const { Provider: GalleryCardsProvider, useTracked: useGalleryCardsTracked } =
  createContainer(useGalleryCards);

export default {
  GalleryCardsProvider,
  useGalleryCardsTracked,
};
