import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { AnchorProvider } from "@coral-xyz/anchor";
import { useEffect, useMemo, useState } from "react";
import { AllstakeSdk, MultiSendAdapter, initAllstakeSdk } from "allstake-sdk";
import config from "../config";
import { useWalletSelector } from "../context/WalletSelectorContext";
import { connection } from "../utils/rpc";
import { useAccount, usePublicClient, useWalletClient } from "wagmi";
import { PublicClient } from "viem";

function useAllstakeSdk() {
  const wallet = useAnchorWallet();
  const provider = useMemo(
    () => new AnchorProvider(connection, wallet as any, {}),
    [wallet],
  );

  const [sdk, setSdk] = useState<AllstakeSdk | null>(null);
  const { selector } = useWalletSelector();
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const { address: ethAddress } = useAccount();

  useEffect(() => {
    if (!sdk?.ethereum?.strategyManagerContract || !walletClient) return;
    sdk.ethereum.strategyManagerContract.setWalletClient(walletClient);
  }, [sdk?.ethereum?.strategyManagerContract, walletClient, ethAddress]);

  useEffect(() => {
    (async function () {
      const _sdk = await initAllstakeSdk({
        near: {
          ...config.allstakeSdkOptions.near,
          adapter: MultiSendAdapter.fromWalletSelector(selector),
        },
        solana: {
          ...config.allstakeSdkOptions.solana,
          provider,
        },
        ethereum: {
          ...config.allstakeSdkOptions.ethereum,
          publicClient: publicClient as PublicClient,
        },
        pyth: config.pyth,
        coingecko: config.coingecko,
      });
      setSdk(_sdk);
    })();
  }, [provider, publicClient, selector]);
  return sdk;
}

export default useAllstakeSdk;
