import clsx from "clsx";
import { twc } from "react-twc";
import { PropsWithChildren } from "react";
import LoadingCircle from "../loading/LoadingCircle";

function StakeButton({
  loading,
  disabled,
  onClick,
  children,
  className,
}: {
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
} & PropsWithChildren) {
  return (
    <ButtonWrapper>
      <StakeButtonStyle
        className={clsx(
          disabled || loading
            ? "bg-[#1F242F] text-[#85888E] cursor-not-allowed"
            : "text-white bg-[#4565E5] hover:bg-[#2A49C7]",
          className,
        )}
        disabled={disabled}
        onClick={onClick}
      >
        {loading ? <LoadingCircle /> : children}
      </StakeButtonStyle>
    </ButtonWrapper>
  );
}

const ButtonWrapper = twc.div`
  flex justify-center items-center
`;

const StakeButtonStyle = twc.button`
  flex justify-center items-center
  duration-300
  rounded-full
  text-lg font-medium
  w-[260px] h-[56px]
`;

export default StakeButton;
